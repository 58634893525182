import { Directive, Input, HostListener, HostBinding } from '@angular/core';

import { MessageBusService, MessageBusChannels } from '../../services/message-bus.service';
import { tooltipId } from './tooltip.component';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective {
  @HostBinding('attr.tabIndex') tabIndex: string = '0';
  @HostBinding('attr.aria-describedby') describedBy: string = tooltipId;

  private tooltipValue: string;

  @Input() public set appTooltip(text: string) {
    this.tooltipValue = text;
  }

  constructor(private messageBusService: MessageBusService) {}

  @HostListener('focus', ['$event'])
  @HostListener('mouseenter', ['$event'])
  public onActive(event: MouseEvent | KeyboardEvent): void {
    this.messageBusService.to(MessageBusChannels.tooltipShow, { text: this.tooltipValue, event: event });
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  @HostListener('document:keydown.escape')
  public onDeactive(): void {
    this.messageBusService.to(MessageBusChannels.tooltipHide, '');
  }
}
