import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';

import { NotificationPosition } from '../../../models/notification.model';
import { IGetScansQuery, IScan } from '../../../../../shared/interfaces/scan.interface';
import { ScanService } from '../../../services/scan.service';
import { NotificationService } from '../../../services/notification.service';
import { TranslateService } from '../../../translate/translate.service';
import { ICurrentSelectedProperty, UserService } from '../../../services/user.service';
import { RestService } from '../../../services/rest.service';
import {
  IDashboardLatestScansServerResponse,
  IDashboardManualEvaluationsBySeverity,
} from '../../../../../shared/interfaces/dashboard-scan-result.interface';
import { $scan } from '../../../../../shared/constants/scan';
import { $severity } from '../../../../../shared/constants/accessibility';
import { AuditFindingQAStatus, AuditFindingQAStatusRemaining } from '../../../../../shared/constants/audit-finding';
import { WorkspacesRestAPI } from '../../../services/rest/workspaces.api';
import { UserPropertyService } from '../../../services/user-property.service';
import { LoadErrorHandler } from '../../../services/load-error-handler';
import { AccessibilityAuditToolNames } from '../../../../../shared/constants/audit-tool';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';

export interface IRecentActivityPreferences {
  limit: number | null;
  filter: Record<string, boolean>;
}

@Injectable()
export class DashboardService {
  constructor(
    private scanService: ScanService,
    private restService: RestService,
    private workspaceRest: WorkspacesRestAPI,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private userPropertyService: UserPropertyService,
    private loadErrorHandler: LoadErrorHandler,
    private userService: UserService,
  ) {}

  public countTotalIssuesForStatus(
    issues: IDashboardManualEvaluationsBySeverity,
    qaStatus: AuditFindingQAStatus | AuditFindingQAStatus[],
    severities: $severity[] = [$severity.critical, $severity.high, $severity.low],
  ): number {
    const sumForSeverity = (severity: $severity): number => {
      let total: number = 0;
      (Array.isArray(qaStatus) ? qaStatus : [qaStatus]).forEach((status: AuditFindingQAStatus): void => {
        if (typeof issues[severity][status] === 'number') {
          total += issues[severity][status];
        }
      });
      return total;
    };
    const reduceToSum = (a: number, b: number): number => a + b;

    return severities.map(sumForSeverity).reduce(reduceToSum, 0);
  }

  public countTotalIssuesForSeverity(
    issues: IDashboardManualEvaluationsBySeverity,
    severity: $severity,
    excludeStatuses: AuditFindingQAStatus | AuditFindingQAStatus[] = [],
  ): number {
    const excluded: AuditFindingQAStatus[] = Array.isArray(excludeStatuses) ? excludeStatuses : [excludeStatuses];

    let total: number = 0;

    [AuditFindingQAStatus.fixed, ...AuditFindingQAStatusRemaining].forEach((qaStatus: AuditFindingQAStatus): void => {
      if (typeof issues[severity][qaStatus] === 'number' && excluded.includes(qaStatus) === false) {
        total += issues[severity][qaStatus];
      }
    });

    return total;
  }

  public handleHttpError(response: HttpErrorResponse, componentName: string): void {
    this.loadErrorHandler.handleError(null, response, componentName);
  }

  public rerunScan(scan: IScan): Subscription {
    const onRerunScanSuccess = (): void => {
      this.notificationService.success(this.translateService.instant('rerun_scan_in_progress'), NotificationPosition.Toast);
    };

    const onRerunScanError = (): void => {
      this.notificationService.error(this.translateService.instant('rerun_scan_error', [scan.url]), NotificationPosition.Toast);
    };

    return this.scanService
      .rerunScan(scan.workspace as unknown as string, scan[$scan.digitalProperty] as string, scan._id)
      .subscribe(onRerunScanSuccess, onRerunScanError);
  }

  public getSelectedWorkspaceManualAudits(): Observable<any> {
    const onGetManualAuditsError = (err: any): Observable<number> => {
      this.notificationService.error(
        this.translateService.instant('error_reading_dashboard_manual_audits'),
        NotificationPosition.Toast,
      );
      return of(0);
    };

    return this.userPropertyService.currentSelectedProperty().pipe(
      filter((property: ICurrentSelectedProperty) => {
        if (SharedCommonUtility.isNullish(property)) {
          this.userService.setToNoWorkspaceState();
          return false;
        }
        return true;
      }),
      mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<any> => {
        return this.workspaceRest.getAllManualEvaluations(workspaceId, digitalPropertyId);
      }),
      catchError(onGetManualAuditsError),
    );
  }

  public getSelectedWorkspaceScansCount(): Observable<number> {
    const onGetScansError = (): Observable<number> => {
      this.notificationService.error(this.translateService.instant('error_reading_dashboard_scans'), NotificationPosition.Toast);
      return of(0);
    };

    return this.scanService.getSelectedWorkspaceScansCount().pipe(catchError(onGetScansError));
  }

  public getLatestScans(
    workspaceId: string,
    digitalPropertyId: string,
    tool: AccessibilityAuditToolNames,
    queryFilter: IGetScansQuery,
    skip: number,
    limit: number,
  ): Observable<IDashboardLatestScansServerResponse> {
    return this.restService.getDashboardLatestScans(workspaceId, digitalPropertyId, tool, queryFilter, skip, limit);
  }
}
