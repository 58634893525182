import { Component, EventEmitter, Input, OnInit, AfterViewChecked, Output, OnChanges } from '@angular/core';
import { of, Observable, isObservable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonUtility } from '../../../utility/common.utility';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';

@Component({
  selector: 'app-responsive-pagination',
  templateUrl: './responsive-pagination.component.html',
  styleUrls: ['./responsive-pagination.component.scss'],
})
export class ResponsivePaginationComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input()
  public collectionSize: number | Observable<number>;
  @Input()
  public entryLimits: number[];
  @Input()
  public label: string;
  @Input()
  public page: number;
  @Input()
  public pageSize: number;
  @Input()
  public preventFocusScroll: boolean;
  @Input()
  public hidePageSizeSelector: boolean;
  @Input()
  public idSuffix: string;
  @Input()
  public hideLabel: boolean;
  @Input()
  public hideNOfT: boolean;
  @Input()
  public tableId: string;
  @Output()
  public pageChange: EventEmitter<number>;
  @Output()
  public pageSizeChange: EventEmitter<number>;
  public selectorId: string;
  private isEllipsesFixed: boolean = false;
  public ready: boolean = false;

  constructor() {
    this.pageChange = new EventEmitter<number>();
    this.pageSizeChange = new EventEmitter<number>();
    this.hidePageSizeSelector = false;
  }

  public get collectionSize$(): Observable<number> {
    if (isObservable(this.collectionSize)) {
      return this.collectionSize;
    }
    return of(this.collectionSize);
  }

  public get startIndex(): Observable<number> {
    return this.collectionSize$.pipe(
      map((size: number): number => Math.min(size, this.page * this.pageSize - this.pageSize + 1)),
    );
  }

  public get lastIndex(): Observable<number> {
    return this.collectionSize$.pipe(map((size: number): number => Math.min(size, this.page * this.pageSize)));
  }

  public handlePageChange(page: number): void {
    this.pageChange.emit(page);
  }

  public ngOnInit(): void {
    this.selectorId = CommonUtility.createUniqueDOMId();
  }

  public ngOnChanges(): void {
    this.ready =
      SharedCommonUtility.notNullish(this.page) &&
      SharedCommonUtility.notNullish(this.pageSize) &&
      SharedCommonUtility.notNullish(this.collectionSize);
  }

  public ngAfterViewChecked(): void {
    if (this.isEllipsesFixed) {
      return;
    }

    const paginationEllipses: NodeListOf<Element> = document.querySelectorAll(
      `a.page-link[tabindex='-1'][aria-disabled]:not([href])`,
    );
    if (SharedCommonUtility.notNullishOrEmpty(paginationEllipses)) {
      paginationEllipses.forEach((ellipsis: HTMLElement) => {
        ellipsis.removeAttribute('aria-disabled');
        ellipsis.removeAttribute('tabindex');
      });
      this.isEllipsesFixed = true;
    }
  }
}
