<ng-container>
  <div class="row justify-content-between mt-5 mb-4">
    <div class="col-12 col-md-auto d-flex flex-column">
      <h3 class="mb-3">{{ title | translate }}</h3>
      <p class="mb-0">{{ description | translate }}</p>
    </div>

    <div class="col-12 col-md-auto d-flex align-items-center">
      <div class="row h-100 align-items-end">
        <div class="col-12 col-md-auto d-flex align-items-center pe-0">
          <label for="manualReviewEntriesPerPage" class="me-2">
            {{ 'label_rows_per_page' | translate }}
          </label>
          <select
            [ngModel]="tableController.entriesPerPage"
            (ngModelChange)="tableController.onEntriesPerPageChange($event)"
            id="manualReviewEntriesPerPage"
            class="me-4"
          >
            <option *ngFor="let val of tableController.availableEntriesPerPage" [ngValue]="val">{{ val }}</option>
          </select>
        </div>

        <div class="col-12 col-md-auto d-flex align-items-center">
          <span class="me-3 text-black">
            {{ tableController.paginationLabel }}
          </span>
          <ngb-pagination
            class="d-flex align-items-center"
            [attr.aria-label]="topPaginationAriaLabel | translate"
            [page]="tableController.page"
            (pageChange)="tableController.onPageChange($event)"
            [pageSize]="tableController.entriesPerPage"
            [collectionSize]="tableController.dataLength"
          >
            <ng-template ngbPaginationPrevious>{{ 'label_previous' | translate }}</ng-template>
            <ng-template ngbPaginationNext>{{ 'label_next' | translate }}</ng-template>
            <ng-template ngbPaginationNumber let-pageNum>
              <span *ngIf="pageNum !== tableController.page" class="visually-hidden">
                {{ 'go_to_page' | translate }}
                &nbsp;
              </span>
              {{ pageNum }}
            </ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-ngb-table
        [config]="tableController.tableConfig"
        [data]="tableController.paginatedData"
        (onSort)="tableController.onTableSort($event)"
      >
      </app-ngb-table>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 d-flex justify-content-end align-items-center">
      <span class="me-3 text-black">
        {{ tableController.paginationLabel }}
      </span>
      <ngb-pagination
        class="d-flex align-items-center"
        [attr.aria-label]="bottomPaginationAriaLabel | translate"
        [page]="tableController.page"
        (pageChange)="tableController.onPageChange($event)"
        [pageSize]="tableController.entriesPerPage"
        [collectionSize]="tableController.dataLength"
      >
        <ng-template ngbPaginationPrevious>{{ 'label_previous' | translate }}</ng-template>
        <ng-template ngbPaginationNext>{{ 'label_next' | translate }}</ng-template>
        <ng-template ngbPaginationNumber let-pageNum>
          <span *ngIf="pageNum !== tableController.page" class="visually-hidden">
            {{ 'go_to_page' | translate }}
            &nbsp;
          </span>
          {{ pageNum }}
        </ng-template>
      </ngb-pagination>
    </div>
  </div>
</ng-container>
