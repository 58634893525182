import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Api } from '../../../../../shared/constants/api';
import { UserDigitalPropertyService } from '../../user-digital-property.service';

export const DigitalPropertyTypeHasScansGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const userDigitalPropertyService: UserDigitalPropertyService = inject(UserDigitalPropertyService);
  const router: Router = inject(Router);
  const forbiddenPage: UrlTree = router.parseUrl(Api.forbidden);

  return userDigitalPropertyService.getAreScansAvailable$().pipe(
    map((scanAvailable: boolean): UrlTree | true => {
      return scanAvailable || forbiddenPage;
    }),
    catchError(() => of(forbiddenPage)),
  );
};
