import { Injectable } from '@angular/core';
import { AuditStandards } from '../../../shared/constants/audit-standard';
import { ISuccessCriteria } from '../../../shared/audits/definitions/success-criteria/success-criteria.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { TranslateService } from '../translate/translate.service';
import { SuccessCriteriaService } from './success-criteria.service';
import { $successCriteria } from '../../../shared/audits/definitions/success-criteria/constants';

export enum Separator {
  newLine = 'newLine',
  comma = 'comma',
}

export enum Divider {
  hyphen = 'hyphen',
}

export enum CriteriaPreset {
  // Displays WCAG and Not applicable success criterias by "num" format. (i.e. 1.1.1)
  criteriaIdentifier = 'criteriaIdentifier',
  // Displays WCAG and Not applicable success criterias by "level" format. (i.e. A)
  criteriaLevel = 'criteriaLevel',
  // Displays WCAG and Not applicable success criterias by "handle" format. (i.e. Non-text Content)
  criteriaHandle = 'criteriaHandle',
  // Displays WCAG and Not applicable success criterias by "num Level level" format. (i.e. 1.1.1 Level AA)
  criteriaIdentifierWithLevel = 'criteriaIdentifierWithLevel',
  // Displays WCAG and Not applicable success criterias by "num handle" format. (i.e. 1.1.1 - Non-text Content)
  criteriaIdentifierWithHandle = 'criteriaIdentifierWithHandle',
  // Displays WCAG and Not applicable success criterias as "num - handle - level" format. (i.e. 1.1.1 - Non-text Content - Level A)
  criteriaFull = 'criteriaFull',
  // Displays WCAG and Not applicable success criterias by "version" format. (i.e. 1.1.1)
  criteriaVersions = 'criteriaVersions',
}

interface IDisplaySuccessCriteria {
  identifiers: string[];
  preset: CriteriaPreset;
  separator?: Separator;
  divider?: Divider;
}

@Injectable({
  providedIn: 'root',
})
export class SuccessCriteriaFormatterService {
  constructor(
    private translateService: TranslateService,
    private successCriteriaService: SuccessCriteriaService,
  ) {}

  /**
   * Formats WCAG and Not applicable success criterias by identifiers.
   * @param identifiers represents an array of identifiers i.e. ['1.1.1', 'Not applicable'].
   * @param formatter transforms the success criteria data to a desired format.
   * @private
   */
  private formatSuccessCriteria(identifiers: string[], formatter: (wcag: ISuccessCriteria) => string): string[] {
    const formattedCriterias: string[] = [];
    identifiers.filter(Boolean).forEach((identifier: string) => {
      if (identifier === AuditStandards.notApplicable) {
        formattedCriterias.push(
          formatter(this.successCriteriaService.getSuccessCriteriaFromStandard(AuditStandards.notApplicable, identifier)),
        );
      }

      const wcag: ISuccessCriteria = this.successCriteriaService.getSuccessCriteriaFromStandard(AuditStandards.wcag, identifier);
      if (SharedCommonUtility.notNullish(wcag)) {
        formattedCriterias.push(formatter(wcag));
      }
    });

    return formattedCriterias;
  }

  private handleDivider(divider: Divider): string {
    let _divider: string = '';

    if (divider === Divider.hyphen) {
      _divider = '-';
    }

    return _divider;
  }

  private handleSeparator(separator: Separator): string {
    let _separator: string = '';

    if (separator === Separator.newLine) {
      _separator = '\n';
    } else if (separator === Separator.comma) {
      _separator = ', ';
    }

    return _separator;
  }

  /**
   * Displays success criterias based on the configured presets and separator.
   * @param identifiers represents success criteria identifier values.
   * @param preset {CriteriaPreset} represents success criteria preset or format.
   * @param separator {Separator} represents separator for success criteria values.
   * @param divider {Divider} represents divider between success criteria properties.
   */
  public toDisplayCriterias({ identifiers, preset, separator, divider }: IDisplaySuccessCriteria): string {
    let criterias: string[] = [];
    const _divider: string = this.handleDivider(divider);

    if (preset === CriteriaPreset.criteriaIdentifier) {
      criterias = this.formatSuccessCriteria(
        identifiers,
        (criteria: ISuccessCriteria): string => `${criteria[$successCriteria.num]}`,
      );
    }

    if (preset === CriteriaPreset.criteriaLevel) {
      criterias = this.formatSuccessCriteria(
        identifiers,
        (criteria: ISuccessCriteria): string => `${criteria[$successCriteria.level]}`,
      );
    }

    if (preset === CriteriaPreset.criteriaHandle) {
      criterias = this.formatSuccessCriteria(
        identifiers,
        (criteria: ISuccessCriteria): string => `${criteria[$successCriteria.handle]}`,
      );
    }

    if (preset === CriteriaPreset.criteriaIdentifierWithHandle) {
      criterias = this.formatSuccessCriteria(
        identifiers,
        (criteria: ISuccessCriteria): string => `${criteria[$successCriteria.num]} ${criteria[$successCriteria.handle]}`,
      );
    }

    if (preset === CriteriaPreset.criteriaIdentifierWithLevel) {
      criterias = this.formatSuccessCriteria(identifiers, (criteria: ISuccessCriteria): string => {
        const level: string = SharedCommonUtility.notNullishOrEmpty(criteria[$successCriteria.level])
          ? ` - ${this.translateService.instant('level')} ${criteria.level}`
          : '';
        return `${criteria[$successCriteria.num]}${level}`;
      });
    }

    if (preset === CriteriaPreset.criteriaFull) {
      criterias = this.formatSuccessCriteria(identifiers, (successCriteria: ISuccessCriteria): string => {
        const level: string = SharedCommonUtility.notNullishOrEmpty(successCriteria[$successCriteria.level])
          ? ` - ${this.translateService.instant('level')} ${successCriteria.level}`
          : '';

        return SharedCommonUtility.notNullishOrEmpty(_divider)
          ? `${successCriteria[$successCriteria.num]} ${_divider} ${successCriteria[$successCriteria.handle]}${level}`
          : `${successCriteria[$successCriteria.num]} ${successCriteria[$successCriteria.handle]}${level}`;
      });
    }

    if (preset === CriteriaPreset.criteriaVersions) {
      criterias = this.formatSuccessCriteria(identifiers, (wcag: ISuccessCriteria): string => `${wcag.versions}`)
        .join('\n')
        .split(',');
    }

    return criterias.filter(Boolean).join(this.handleSeparator(separator));
  }
}
