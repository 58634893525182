<ds-input [label]="label | translate"  [state]="state">
  <select
    ds-input-field
    #webAppSelect
    [(ngModel)]="selectedWebApp"
    (ngModelChange)="selectWebAppChanged($event)"
    [ds-tooltip]="selectWebAppTooltip"
  >
    <option *ngFor="let digitalProperty of digitalProperties$ | async" [value]="digitalProperty[$digitalProperty._id]">
      {{ digitalProperty[$digitalProperty.name] }}
    </option>
    <hr />
    <option #webAppSelectPortfolioOption [value]="portfolioValue">
      {{ 'website_and_app_portfolio_label' | translate }}
    </option>
  </select>
</ds-input>
