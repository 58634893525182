export enum $user {
  _entriesAmount = 'entriesAmount',
  _id = '_id',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  accountStatus = 'accountStatus',
  applicationPermissions = 'applicationPermissions',
  applicationRoles = 'applicationRoles',
  authenticated = 'authenticated',
  avatar = 'avatar',
  clientId = 'clientId',
  company = 'company',
  companyDepartment = 'companyDepartment',
  country = 'country',
  createdAt = 'createdAt',
  createdVia = 'createdVia',
  currentPassword = 'currentPassword',
  currentTenantId = 'currentTenantId',
  deactivationAccountReasons = 'deactivationAccountReasons',
  displayName = 'displayName',
  email = 'email',
  emailVerified = 'emailVerified',
  firstName = 'firstName',
  invitation = 'invitation',
  isResetPasswordTokenValid = 'isResetPasswordTokenValid',
  jobIndustry = 'jobIndustry',
  jobTitle = 'jobTitle',
  lastActiveSession = 'lastActiveSession',
  lastLogin = 'lastLogin',
  lastName = 'lastName',
  lastPasswordChange = 'lastPasswordChange',
  lastUsedWorkspace = 'lastUsedWorkspace',
  loginFailures = 'loginFailures',
  newPassword = 'newPassword',
  password = 'password',
  mfaSecret = 'mfaSecret',
  mfaStatus = 'mfaStatus',
  phoneNumber = 'phoneNumber',
  roles = 'roles',
  securitySet = 'securitySet',
  timezone = 'timezone',
  timezoneOffset = 'timezoneOffset',
  updatedAt = 'updatedAt',
  userLanguage = 'userLanguage',
  workspacePermissions = 'workspacePermissions',
  workspaceRoles = 'workspaceRoles',
  workspaces = 'workspaces',
  workspacesIds = 'workspacesIds',
  lastUsedDigitalProperty = 'lastUsedDigitalProperty',
  digitalProperties = 'digitalProperties',
  verificationEmailExpirationDate = 'verificationEmailExpirationDate',
  groupIds = 'groupIds',
  groups = 'groups',
  organizationAdministrator = 'organizationAdministrator', // TODO: verify if this is needed
  tenants = 'tenants',
  lastUsedResources = 'lastUsedResources',
  recentResourcesByTenant = 'recentResourcesByTenant',
  type = 'type',
  previousPasswords = 'previousPasswords',
  roleId = 'roleId',
  scimAttributes = 'scimAttributes',
  deleted = 'deleted',
}

export const ADMIN_KEY: string = 'ADMIN_KEY';

export const MAXIMUM_NUMBER_OF_PREVIOUS_PASSWORDS: Readonly<number> = 10;

export enum $mfaStatus {
  enabled = 'enabled',
  disabled = 'disabled',
}

export enum $userSearch {
  searchTerm = 'searchTerm',
}

export enum accountStatus {
  active = 'active',
  inactive = 'inactive',
  closed = 'closed',
}

export enum adminSendingInvitationStatus {
  sent = 'sent',
  notValid = 'notValid',
  existingEmail = 'existingEmail',
}

export const userPersonalWorkspaceDomainPrefix: string = '#';

export enum createdViaOtherMean {
  viaDocument = 'viaDocument',
  viaUsersGroupInvitation = 'viaUsersGroupInvitation',
  viaWorkspaceInvitation = 'viaWorkspaceInvitation',
  viaSecurityGroupCreation = 'viaSecurityGroupCreation',
  viaScim = 'viaScim',
}

export enum userDeletedVia {
  viaScim = 'viaScim',
}

export enum userColumnHeader {
  action = 'action',
}

export const minPasswordLength: number = 10;

export enum $getOrCreateUserResponse {
  existingUsers = 'existingUsers',
  newUsers = 'newUsers',
}

export enum $assignUsersResponse {
  assignedUsers = 'assignedUsers',
}

export enum $userResources {
  workspaceId = 'workspaceId',
  digitalPropertyId = 'digitalPropertyId',
}

export enum UserType {
  general = 'general',
  amp_service = 'amp_service',
  scim_service = 'scim_service',
}

export const SYSTEM_USER_TYPES: UserType[] = [UserType.amp_service, UserType.scim_service];

export enum EmailUpdateError {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  STAFF_USER = 'STAFF_USER',
}

export enum $scimAttributes {
  emails = 'emails',
  externalId = 'externalId',
  groups = 'groups',
  userName = 'userName',
}

export enum $scimUserEmail {
  primary = 'primary',
  value = 'value',
}

export enum $scimUserGroup {
  display = 'display',
}
