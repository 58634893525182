<div class="row margin-bottom-md">
  <div class="col-12 col-lg-6 d-flex flex-column" >
    <div class="label">{{ 'audit_rule_property_rule_id' | translate }}</div>
    <div class="content">{{ rule.ruleId }}</div>
  </div>
</div>

<div class="row margin-bottom-md">
  <div class="col-12 col-lg-6 d-flex flex-column" >
    <div class="label">{{ 'audit_rule_property_description' | translate }}</div>
    <div class="content">{{ rule.description }}</div>
  </div>
</div>

<div class="row margin-bottom-md" >
  <div class="col-6 col-xl-2 d-flex flex-column">
    <div class="label">{{ 'label_wcag_success_critera' | translate }}</div>
    <div *ngFor="let standard of rule.standards">
      <span class="content">{{ displayWCAGOrNACriteria(standard) }}</span>
    </div>
  </div>
  <div class="col-6 col-xl-2 d-flex flex-column">
    <div class="label">{{ 'version' | translate }}</div>
    <div *ngFor="let standard of rule.standards">
      <span class="content"> {{ standard.WCAG?.versions | arraytostring }} </span>
    </div>
  </div>
</div>

<div class="row margin-bottom-md">
  <div class="col-4 col-xl-2" >
    <div class="label">{{ 'audit_rule_property_severity' | translate }}</div>
    <app-rule-severity [severity]="rule.severity"></app-rule-severity>
  </div>
  <div *ngIf="rule.libraryName" class="col-4 col-xl-2 d-flex flex-column" >
    <div class="label">{{ 'library' | translate }}</div>
    <div class="content">
      {{ rule.libraryName }}
    </div>
  </div>
  <div *ngIf="rule.category" class="col-4 col-xl-2 d-flex flex-column" >
    <div class="label">{{ 'audit_rule_property_category' | translate }}</div>
    <div class="content">{{ rule.category }}</div>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.stepsToReproduce">
  <div class="col-12 col-lg-6 d-flex flex-column">
    <div class="label">{{ 'issue_field_stepsToReproduce' | translate }}</div>
    <div class="content pre-line" [appHighlight]="rule.stepsToReproduce" type="html" [linkify]="true"></div>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.actualResult">
  <div class="col-12 col-lg-6 d-flex flex-column">
    <div class="label">{{ 'issue_field_actualResult' | translate }}</div>
    <ng-container *ngIf="rule.isAccessipediaRule; else otherRule">
      <div class="content pre-line" [innerHTML]="rule.actualResult"></div>
    </ng-container>
    <ng-template #otherRule>
      <div class="content pre-line" [appHighlight]="rule.actualResult" type="html" [linkify]="true"></div>
    </ng-template>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.recommendation">
  <div class="col-12 col-lg-6 d-flex flex-column">
    <div class="label">{{ 'issue_field_recommendation' | translate }}</div>
    <ng-container *ngIf="rule.isAccessipediaRule; else otherRule">
      <div class="content pre-line" [innerHTML]="rule.recommendation"></div>
    </ng-container>
    <ng-template #otherRule>
      <div class="content pre-line" [appHighlight]="rule.recommendation" type="html" [linkify]="true"></div>
    </ng-template>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.functionalImpact">
  <div class="col-12 col-lg-6 d-flex flex-column">
    <div class="label">{{ 'issue_field_functionalImpact' | translate }}</div>
    <div class="content">{{ 'label_issue_effort_' + rule.functionalImpact | translate }}</div>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.disabilitiesAffected">
  <div class="col-12 col-lg-6 d-flex flex-column">
    <div class="label">{{ 'issue_field_disabilityAffected' | translate }}</div>
    <ul class="list-unstyled">
      <li *ngFor="let disability of rule.disabilitiesAffected">
        {{ 'issue_field_disability_' + disability | translate }}
      </li>
    </ul>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.applicableElementsSelectors">
  <div class="col-12 col-lg-6 d-flex flex-column" >
    <div class="label">{{ 'issue_field_selectors' | translate }}</div>
    <pre>{{ rule.applicableElementsSelectors.join('\n') }}</pre>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.testId">
  <div class="col-12 col-lg-6 d-flex flex-column">
    <div class="label">{{ 'audit_rule_property_test_id' | translate }}</div>
    <div class="content">{{ rule.testId }}</div>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.testName">
  <div class="col-12 col-lg-6 d-flex flex-column">
    <div class="label">{{ 'audit_rule_property_test_name' | translate }}</div>
    <div class="content">{{ rule.testName }}</div>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.testManualSteps">
  <div class="col-12 col-lg-6 d-flex flex-column">
    <div class="label">{{ 'audit_rule_property_test_manual_steps' | translate }}</div>
    <div class="content" [innerHTML]="rule.testManualSteps"></div>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.referencedRule?.tool === AuditToolNames.access_engine">
  <h2>{{ 'audit_rule_property_mapped_automated_tests' | translate }}</h2>

  <div class="col-12 col-lg-6 d-flex flex-column">
    <div class="label">{{ 'audit_rule_property_mapped_automated_rule_id' | translate }}</div>
    <div class="content">{{ rule.referencedRule?.ruleId }} {{ rule.referencedRule?.title }}</div>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.compliantCodeExample">
  <div class="col-12 col-lg-6 d-flex flex-column">
    <div class="label">{{ 'label_compliant_code_example' | translate }}</div>
    <pre><code class="content d-block p-2" [appHighlight]="rule.compliantCodeExample" type="javascript"></code></pre>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.nonCompliantCodeExample">
  <div class="col-12 col-lg-6 d-flex flex-column">
    <div class="label">{{ 'label_non-compliant_code_example' | translate }}</div>
    <pre><code class="content d-block p-2" [appHighlight]="rule.nonCompliantCodeExample" type="javascript"></code></pre>
  </div>
</div>

<div class="row margin-bottom-md" *ngIf="rule.techniques?.length > 0">
  <div class="col-12 col-lg-6 d-flex flex-column" >
    <div class="label">{{ 'audit_rule_property_techniques' | translate }}</div>
    <div class="content">
      <div *ngFor="let technique of rule.techniques">
        {{ technique }}
      </div>
    </div>
  </div>
</div>
