import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCellComponent, ICellConfig, IIconConfig } from '../base-cell/base-cell.component';

export interface IButtonCellConfig extends ICellConfig {
  icon?: IIconConfig;
  isDisabled$?: Observable<boolean>;
}

@Component({
  selector: 'table-cell-button',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <button class="btn" [disabled]="config?.isDisabled$ | async" #ref>
      <ng-container *ngIf="config.icon">
        <svg
          aria-hidden="true"
          [attr.width]="config.icon.iconWidth"
          [attr.height]="config.icon.iconHeight"
          [ngStyle]="config.icon.iconStyles"
        >
          <use [attr.xlink:href]="'#' + config.icon.iconName"></use>
        </svg>
      </ng-container>
      {{ config.text }}
    </button>
  `,
})
export class ButtonCellComponent extends BaseCellComponent {
  @Input() public config: IButtonCellConfig;
}
