import {
  AfterViewInit,
  Component,
  HostListener,
  ElementRef,
  Input,
  ChangeDetectorRef,
  Renderer2,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { Options as FocusTrapOptions } from 'focus-trap';
import { DsButtonVariants, DsTooltipPosition, Icons, IconStyles } from '@levelaccess/design-system';
import { isNull } from 'lodash';
import { Observable } from 'rxjs';

import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';
import { TranslateService } from '../../../../../translate/translate.service';
import { CommonUtility } from '../../../../../utility/common.utility';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface IDropdownItem {
  label: string;
  clickListener: () => any;
  disabled?: boolean;
  icon?: Icons;
  translate?: boolean;
}

export interface IDropdownCellConfig extends ICellConfig {
  ellipsisIcon?: boolean;
  largeIcon?: boolean;
  ariaLabel?: string;
  icon?: Icons;
  iconTooltip?: string;
  dropdownItems: IDropdownItem[];
  isDisabled?: Observable<boolean>;
}

@Component({
  selector: 'table-cell-dropdown',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <div #ref [class.dropdown-cell]="!config.ellipsisIcon" [class.dropdown-cell-action]="config.ellipsisIcon">
      <ng-container *ngIf="config.icon">
        <ds-icon
          tabindex="0"
          class="pt-1"
          [icon]="config.icon"
          [tooltipPlacement]="DsTooltipPosition.top"
          [ds-tooltip]="config.iconTooltip"
          [attr.aria-label]="config.iconTooltip"
        />
      </ng-container>
      <button
        class="btn dropdown-toggle"
        [class.ellipsis-icon]="config.ellipsisIcon"
        [class.large-icon]="config.largeIcon"
        [disabled]="config?.dropdownItems.length === 0 || (config?.isDisabled | async) === true"
        [id]="'dropdownButton_' + menuIdSuffix"
        [attr.aria-label]="ariaLabel"
        [attr.aria-expanded]="isOpen"
        [attr.aria-controls]="'dropdownButton_' + menuIdSuffix + '_menu'"
        (click)="toggle($event)"
      >
        <span [attr.aria-hidden]="!config.text">
          <ng-container *ngIf="config.text">
            {{ config.translate === false ? config.text : (config.text | translate) }}
          </ng-container>
        </span>
      </button>
      <div
        *ngIf="isOpen"
        [attr.aria-labelledby]="'dropdownButton_' + menuIdSuffix"
        role="menu"
        [id]="'dropdownButton_' + menuIdSuffix + '_menu'"
        [ngStyle]="{ 'top.px': y + 10 + (yOffset1 - yOffset2), 'left.px': x - 100, 'max-height': maxHeight }"
      >
        <ng-container *ngFor="let dropdownItem of config.dropdownItems; let index = index">
          <button
            *ngIf="dropdownItem.icon; else actionButton"
            ds-button
            [id]="generateUniqueId(index)"
            [variant]="DsButtonVariants.microActionHorizontalLayout"
            [microActionIcon]="dropdownItem.icon"
            [disabled]="isDisabled(dropdownItem)"
            (click)="dropdownItem.clickListener(); toggle($event)"
            class="px-3 py-2"
            role="menuitem"
          >
            {{ dropdownItem.translate === false ? dropdownItem.label : (dropdownItem.label | translate) }}
          </button>
          <ng-template #actionButton>
            <button
              [id]="generateUniqueId(index)"
              class="dropdown-item"
              role="menuitem"
              [disabled]="isDisabled(dropdownItem)"
              (click)="dropdownItem.clickListener(); toggle($event)"
            >
              {{ dropdownItem.translate === false ? dropdownItem.label : (dropdownItem.label | translate) }}
            </button>
          </ng-template>
        </ng-container>
      </div>
    </div>
  `,
})
export class DropdownCellComponent extends BaseCellComponent implements OnDestroy, AfterViewInit {
  public static cellDataType: string = 'DropdownCellComponent';
  public isOpen: boolean = false;
  public x: number = 0;
  public y: number = 0;
  public xOffset1: number = 0;
  public yOffset1: number = 0;
  public xOffset2: number = 0;
  public yOffset2: number = 0;
  @Input() public config: IDropdownCellConfig;
  public menuIdSuffix: string;
  public initialFocus: string;
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public readonly Icons: typeof Icons = Icons;
  protected readonly IconStyles: typeof IconStyles = IconStyles;
  protected readonly DsTooltipPosition: typeof DsTooltipPosition = DsTooltipPosition;
  protected maxHeight: string;

  constructor(
    renderer: Renderer2,
    private zone: NgZone,
    private elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
    private translationService: TranslateService,
  ) {
    super(renderer);
    this.menuIdSuffix = SharedCommonUtility.getRandomInsecureString();
  }

  public generateUniqueId(index: number): string {
    return 'dropdown-menu-action-button_' + (index + 1);
  }

  ngAfterViewInit(): void {
    this.initialFocus = 'button[role="menuitem"]';
  }

  private setMaxHeight(viewPortTop: number): void {
    const totalHeight: number = document.documentElement.scrollHeight;
    const absoluteTop: number = viewPortTop + window.scrollY;
    const maxHeight: number = Math.min(totalHeight - absoluteTop, 336);

    this.maxHeight = `${maxHeight}px`;
  }

  public isDisabled(item: IDropdownItem): boolean {
    return item.disabled ? item.disabled : false;
  }

  @HostListener('window:scroll', ['$event'])
  public onWindowScroll(): void {
    if (this.isOpen) {
      this.xOffset2 = window.scrollX;
      this.yOffset2 = window.scrollY;
    }
  }

  @HostListener('window:resize', ['$event'])
  public onWindowResize(): void {
    if (this.isOpen) {
      this.toggle();
    }
  }

  @HostListener('document:click', ['$event'])
  public onClickHandler(event: Event): void {
    if (this.isOpen && this.elementRef.nativeElement.contains(event.target as Node) === false) {
      this.toggle();
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  public onEscapeKeydownHandler(): void {
    if (this.isOpen) {
      this.toggle();
    }
  }

  @HostListener('keydown.arrowUp', ['$event'])
  public onArrowUpKeydownHandler(event: KeyboardEvent): boolean {
    if (this.isOpen) {
      event.preventDefault();
      if (document.activeElement && document.activeElement.closest('div[role="menu"]')) {
        const focusable: HTMLCollection = document.activeElement.parentNode.children;
        if (focusable.length > 0 && document.activeElement.parentNode.firstElementChild === document.activeElement) {
          CommonUtility.setFocusToElement(
            document.activeElement.parentNode.lastElementChild.id,
            document.activeElement.parentNode.lastElementChild as HTMLElement,
          );
        } else if (focusable.length > 0) {
          const currentIndex: number = Array.from(focusable).indexOf(document.activeElement);
          const previousElement: HTMLElement = focusable.item(currentIndex - 1) as HTMLElement;
          CommonUtility.setFocusToElement(previousElement.id, previousElement);
        }

        this.changeDetectorRef.detectChanges();

        return false;
      }
    }
    return true;
  }

  @HostListener('keydown.arrowDown', ['$event'])
  public onArrowDownKeydownHandler(event: KeyboardEvent): boolean {
    if (this.isOpen) {
      event.preventDefault();
      if (document.activeElement && document.activeElement.closest('div[role="menu"]')) {
        const focusable: HTMLCollection = document.activeElement.parentNode.children;
        if (focusable.length > 0 && document.activeElement.parentNode.lastElementChild === document.activeElement) {
          CommonUtility.setFocusToElement(
            document.activeElement.parentNode.firstElementChild.id,
            document.activeElement.parentNode.firstElementChild as HTMLElement,
          );
        } else if (focusable.length > 0) {
          const currentIndex: number = Array.from(focusable).indexOf(document.activeElement);
          const nextElement: HTMLElement = focusable.item(currentIndex + 1) as HTMLElement;
          CommonUtility.setFocusToElement(nextElement.id, nextElement);

          this.changeDetectorRef.detectChanges();
        }
        return false;
      }
    }
    return true;
  }

  public toggle(event?: MouseEvent): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      if (event) {
        const boundingRect: { x: number; y: number } = (event.target as HTMLElement).getBoundingClientRect();
        this.x = event.clientX || boundingRect.x;
        this.y = event.clientY || boundingRect.y;
        this.xOffset1 = this.xOffset2 = window.scrollX;
        this.yOffset1 = this.yOffset2 = window.scrollY;

        this.setMaxHeight(this.y + 10);
      }

      this.zone.runOutsideAngular(() => {
        const focusTrapOptionsWithInitialFocus: FocusTrapOptions = {
          initialFocus: this.initialFocus,
        };

        if (this.initialFocus && this.initialFocusExistsOnPage()) {
          setTimeout(() => {
            CommonUtility.activateFocusTrap(this.elementRef.nativeElement, focusTrapOptionsWithInitialFocus);
          }, 0);
        } else {
          setTimeout(() => {
            CommonUtility.activateFocusTrap(this.elementRef.nativeElement);
          }, 0);
        }
      });
    } else {
      CommonUtility.deactivateFocusTrap();
    }
    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    CommonUtility.deactivateFocusTrap();
  }

  public get ariaLabel(): string {
    if (this.config.ariaLabel) {
      return this.config.ariaLabel;
    } else if (!this.config.text) {
      if (this.config.dropdownItems.length > 0) {
        return this.translationService.instant('show_actions_dropdown');
      }
      return this.translationService.instant('no_actions_available');
    }
    return null;
  }

  private initialFocusExistsOnPage(): boolean {
    return !isNull(this.elementRef.nativeElement.querySelector(this.initialFocus));
  }
}
