import { Injectable } from '@angular/core';
import { AuditStandards } from '../../../shared/constants/audit-standard';
import {
  ISuccessCriteria,
  ISuccessCriteriaIndex,
} from '../../../shared/audits/definitions/success-criteria/success-criteria.interface';
import { Legacy } from '../../../shared/audits/definitions/success-criteria/legacy';
import { Wcag } from '../../../shared/audits/definitions/success-criteria/wcag';
import { Axe } from '../../../shared/audits/definitions/success-criteria/axe';
import { Aslint } from '../../../shared/audits/definitions/success-criteria/aslint';
import { Wave } from '../../../shared/audits/definitions/success-criteria/wave';
import { NotApplicable } from '../../../shared/audits/definitions/success-criteria/notApplicable';

@Injectable({
  providedIn: 'root',
})
export class SuccessCriteriaService {
  private readonly legacySuccessCriteria: ISuccessCriteriaIndex;
  private standardToSuccessCriteriaIndex: Record<AuditStandards, ISuccessCriteriaIndex>;

  constructor() {
    this.legacySuccessCriteria = new Legacy();
    this.standardToSuccessCriteriaIndex = {
      [AuditStandards.wcag]: new Wcag(),
      [AuditStandards.axe]: new Axe(),
      [AuditStandards.essential]: new Aslint(),
      [AuditStandards.wave]: new Wave(),
      [AuditStandards.css]: this.legacySuccessCriteria,
      [AuditStandards.html]: this.legacySuccessCriteria,
      [AuditStandards.notApplicable]: new NotApplicable(),
    };
  }

  public getSuccessCriteriaFromStandard(standardIdentifier: AuditStandards, identifier: string): ISuccessCriteria | null {
    return this.standardToSuccessCriteriaIndex[standardIdentifier].get(identifier);
  }

  public getSuccessCriteriaListFromStandard(standardIdentifier: AuditStandards, identifiers: string[] = []): ISuccessCriteria[] {
    return identifiers
      .map((identifier: string): ISuccessCriteria | null => this.getSuccessCriteriaFromStandard(standardIdentifier, identifier))
      .filter((criteria: ISuccessCriteria | null): boolean => criteria !== null);
  }

  public getAllSuccessCriteriaFromStandard(standardIdentifier: AuditStandards): ISuccessCriteria[] {
    return this.standardToSuccessCriteriaIndex[standardIdentifier].getAll();
  }

  /**
   * Gets all WCAG and Not applicable success criterias in raw format.
   */
  public getAllWCAGWithNACriteriaFromStandard(): ISuccessCriteria[] {
    return [
      ...this.getAllSuccessCriteriaFromStandard(AuditStandards.wcag),
      ...this.getAllSuccessCriteriaFromStandard(AuditStandards.notApplicable),
    ].filter((criteria: ISuccessCriteria | null): boolean => criteria !== null);
  }

  /**
   * Transforms the successCriteria server data to the format/interface used in the frontend.
   * @param identifiers represents success criteria identifier values (i.e. 1.1.1, 2.1.1).
   */
  public getWCAGWithNACriteriasByIdentifier(identifiers: string[]): ISuccessCriteria[] {
    return identifiers
      .map((identifier: string): ISuccessCriteria => {
        if (identifier === AuditStandards.notApplicable) {
          return this.getSuccessCriteriaFromStandard(AuditStandards.notApplicable, identifier);
        }

        return this.getSuccessCriteriaFromStandard(AuditStandards.wcag, identifier);
      })
      .filter((criteria: ISuccessCriteria | null): boolean => criteria !== null);
  }
}
