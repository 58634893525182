export enum $auditStandard {
  _id = '_id',
  identifier = 'identifier',
  standard = 'standard',
  version = 'version',
  level = 'level',
  isDisabled = 'isDisabled',
}

export enum $scanAuditStandard {
  id = 'id',
  description = 'description',
  url = 'url',
}

export enum AuditStandards {
  wcag = 'WCAG',
  axe = 'Axe',
  essential = 'eSSENTIAL',
  wave = 'Wave',
  css = 'CSS3',
  html = 'HTML5',
  notApplicable = 'Not applicable',
}

export enum AuditDbStandard {
  wcag = 'WCAG',
  axe = 'DEQUEBP',
  essential = 'EABP',
  wave = 'WAVEBP',
  notApplicable = 'NOT_APPLICABLE',
}

export const mapAuditDbStandardToAuditStandard: Record<AuditDbStandard, AuditStandards> = {
  [AuditDbStandard.wcag]: AuditStandards.wcag,
  [AuditDbStandard.axe]: AuditStandards.axe,
  [AuditDbStandard.essential]: AuditStandards.essential,
  [AuditDbStandard.wave]: AuditStandards.wave,
  [AuditDbStandard.notApplicable]: AuditStandards.notApplicable,
};
