import { marked, Renderer } from 'marked';
import DOMPurify from 'isomorphic-dompurify';
import TurndownService from 'turndown';

import { SharedTextUtility } from './text.utility';
import { SharedCommonUtility } from './common.utility';

enum TokenType {
  HEADING = 'heading',
  HR = 'hr',
  LINK = 'link',
  LIST = 'list',
  TABLE = 'table',
  TEXT = 'text',
}

export enum RenderType {
  DEFAULT = 'DEFAULT',
  NOTIFICATION = 'NOTIFICATION',
}

interface Token {
  type: TokenType;
  raw: string;
  text?: string;
}

interface MarkedOptions {
  renderer: Renderer;
  walkTokens?: ((token: Token) => void) | undefined;
  breaks?: boolean | undefined;
}

export class MarkdownUtility {
  private static readonly markedOptions: Map<RenderType, MarkedOptions> = new Map();
  private static readonly tokenTypesToChangeForNotification: Set<string> = new Set([
    TokenType.HEADING,
    TokenType.HR,
    TokenType.LINK,
    TokenType.LIST,
    TokenType.TABLE,
  ]);

  private static readonly turndownService: TurndownService = new TurndownService({
    headingStyle: 'atx',
  });

  /**
   * Special symbols combination that is replaced with <br> tag by the marked library
   */
  public static readonly br: string = '  \n';

  private static getMarkedOptions(type: RenderType): any {
    if (!this.markedOptions.has(type)) {
      this.markedOptions.set(type, this.buildMarkedOptions(type));
    }

    return this.markedOptions.get(type);
  }

  private static buildMarkedOptions(type: RenderType): any {
    switch (type) {
      case RenderType.NOTIFICATION:
        return this.buildMarkedOptionsForNotification();
      default:
        return this.buildDefaultMarkedOptions();
    }
  }

  private static buildMarkedOptionsForNotification(): any {
    const renderer: Renderer = new Renderer();

    renderer.code = function (text: string): string {
      return `<code>${text}</code>`;
    };

    renderer.codespan = function (text: string): string {
      return `<code>${SharedTextUtility.unescapeAmpersands(text)}</code>`;
    };

    renderer.paragraph = renderer.text;

    return {
      renderer: renderer,
      walkTokens: this.changeTokenTypeToText,
    };
  }

  private static changeTokenTypeToText = (token: Token): void => {
    if (MarkdownUtility.tokenTypesToChangeForNotification.has(token.type)) {
      token.type = TokenType.TEXT;

      if (SharedCommonUtility.isNullish(token['text'])) {
        token['text'] = token.raw;
      }
    }
  };

  private static buildDefaultMarkedOptions(): MarkedOptions {
    const renderer = new Renderer();

    renderer.code = function (text: string): string {
      return `<pre><code>${text}</code></pre>`;
    };

    renderer.codespan = function (text: string): string {
      return `<code>${SharedTextUtility.unescapeAmpersands(text)}</code>`;
    };

    return {
      breaks: true,
      renderer: renderer,
    };
  }

  public static render(value: string, type: RenderType = RenderType.DEFAULT): string {
    const escapedValue: string = SharedTextUtility.escapeHtmlTags(value);
    const compiledHtml: string = marked(escapedValue, this.getMarkedOptions(type));

    return DOMPurify.sanitize(compiledHtml);
  }

  public static sanitize(value: string, options: Record<string, any> = {}): string {
    const workingValue: string = SharedTextUtility.escapeHtmlTags(value);
    return DOMPurify.sanitize(workingValue, {
      USE_PROFILES: { html: false },
      ...options,
    });
  }

  /**
   * Converts html content to markdown.
   *
   * @param html html content to convert to markdown.
   * @param options options for the conversion.
   * @param {boolean} [options.keepCodeTagsContent=true] Whether to preserve the content inside code tags. If false, the content inside code tags will also be converted to its html representation.
   *
   * @returns The markdown representation of the provided html.
   */
  public static htmlToMarkdown(html: string, options: { keepCodeTagsContent: boolean } = { keepCodeTagsContent: true }): string {
    if (options.keepCodeTagsContent) {
      const regex: RegExp = new RegExp(/<code>(.*?)<\/code>/g);
      const escapedHtml: string = html.replace(regex, (_: string, innerContent: string): string => {
        return `<code>${SharedTextUtility.escapeHtmlTags(innerContent)}</code>`;
      });

      return this.turndownService.turndown(escapedHtml);
    }
    return this.turndownService.turndown(html);
  }
}
