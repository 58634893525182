import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { DigitalPropertyService } from '../../digital-property.service';
import { Api } from '../../../../../shared/constants/api';
import { IDigitalPropertyListItem, IDigitalPropertyType } from '../../../../../shared/interfaces/digital-property.interface';
import { $digitalProperty, $digitalPropertyType } from '../../../../../shared/constants/digital-properties';
import { UserService } from '../../user.service';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';

export const WebDigitalPropertyGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const digitalPropertyService: DigitalPropertyService = inject(DigitalPropertyService);
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);
  const forbiddenPage: UrlTree = router.parseUrl(Api.forbidden);

  return combineLatest([userService.currentDigitalProperty$, digitalPropertyService.getDigitalPropertyTypes()]).pipe(
    map(([digitalProperty, digitalPropertyTypes]: [IDigitalPropertyListItem | null, IDigitalPropertyType[]]) => {
      if (SharedCommonUtility.isNullish(digitalProperty)) {
        return forbiddenPage;
      }
      const digitalPropertyType: IDigitalPropertyType = digitalPropertyTypes.find(
        (type: IDigitalPropertyType) => type[$digitalPropertyType._id] === digitalProperty[$digitalProperty.typeId],
      );
      return digitalPropertyService.isWebsiteType(digitalPropertyType) || forbiddenPage;
    }),
    catchError(() => of(forbiddenPage)),
  );
};
