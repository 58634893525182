export enum EapColors {
  BlueInteraction = '#0852ba',
  BlueHover = '#06367a',
  BlueLink = '#0665d0',
  BlueInactive = '#83a8dd',
  BlueBackground = '#f3f6fc',
  BlueInfo = '#0098C7',
  BlueText = '#063E8D',
  BluePrimary = '#074EB0',
  BlueHoverBackground = '#f3f6fc',
  GreenSeverityLow = '#4b9e24',
  GreenCheckmark = '#20652a',
  GreyCopy = '#333',
  GreyStrokeInactive = '#8c8c8c',
  GreyPageBackground = '#f5f5f5',
  GreenStatusPassed = '#569e14',
  PurpleFocus = '#a30083',
  RedError = '#b10606',
  RedStatusFailed = '#ea3349',
  RedSeverityCritical = '#f4364c',
  OrangeSeverityHigh = '#db6e00',
  OrangeStatusReview = '#d36d00',
  White = '#fff',
}
