<div class="row justify-content-between">
  <div aria-live="polite" role="status" class="visuallyhidden">
    {{ 'v_table_has_been_loaded' | translate: ('view_by' | translate) + ' ' + ('rule' | translate) }}
  </div>
  <div class="col-12 col-md-auto d-flex align-items-center mb-3">
    <app-rule-filter
      [status]="issueStatusFilter"
      (onFilterChanged)="filterStatus($event)"
      [failedRulesCount]="failedRulesCount"
      [reviewRulesCount]="reviewRulesCount"
      [passedRulesCount]="passedRulesCount"
      [hidePassedRules]="hasComponentId || auditTool === AccessibilityAuditToolNames.wave"
    ></app-rule-filter>
  </div>
  <div class="col-12 col-md-auto d-flex align-items-center">
    <div class="row">
      <div class="col-12 col-md-auto d-flex align-items-center mb-3 pe-0">
        <label for="entriesPerPage" class="me-2">
          {{ 'label_rows_per_page' | translate }}
        </label>
        <select [ngModel]="entriesPerPage" (ngModelChange)="onEntriesPerPageChange($event)" id="entriesPerPage" class="me-4">
          <option *ngFor="let val of entriesAmounts" [ngValue]="val">{{ val }}</option>
        </select>
      </div>
      <div class="col-12 col-md-auto d-flex align-items-center mb-3">
        <span class="me-3 text-black">{{ getPaginationLabel(page, entriesPerPage, filteredTableData.length) }}</span>
        <ngb-pagination
          class="d-flex align-items-center"
          [attr.aria-label]="'aria_label_scan_results_issues_table_top_pagination' | translate"
          [page]="page"
          (pageChange)="onPageChange($event)"
          [pageSize]="entriesPerPage"
          [collectionSize]="filteredTableData.length"
        >
          <ng-template ngbPaginationPrevious>{{ 'label_previous' | translate }}</ng-template>
          <ng-template ngbPaginationNext>{{ 'label_next' | translate }}</ng-template>
          <ng-template ngbPaginationNumber let-pageNum>
            <span *ngIf="pageNum !== page" class="visually-hidden">
              {{ 'go_to_page' | translate }}
              &nbsp;
            </span>
            {{ pageNum }}
          </ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <app-ngb-table
      
      #issuesTable
      [config]="filteredTableConfig"
      [data]="filteredPaginatedTableData"
      (onSort)="onTableSort($event)"
    >
    </app-ngb-table>
  </div>
</div>

<div class="row mt-3">
  <div class="col-12 d-flex justify-content-end align-items-center">
    <span class="me-3 text-black">{{ getPaginationLabel(page, entriesPerPage, filteredTableData.length) }}</span>
    <ngb-pagination
      class="d-flex align-items-center"
      [attr.aria-label]="'aria_label_scan_results_issues_table_bottom_pagination' | translate"
      [page]="page"
      (pageChange)="onPageChange($event)"
      [pageSize]="entriesPerPage"
      [collectionSize]="filteredTableData.length"
    >
      <ng-template ngbPaginationPrevious>{{ 'label_previous' | translate }}</ng-template>
      <ng-template ngbPaginationNext>{{ 'label_next' | translate }}</ng-template>
      <ng-template ngbPaginationNumber let-pageNum>
        <span *ngIf="pageNum !== page" class="visually-hidden">
          {{ 'go_to_page' | translate }}
          &nbsp;
        </span>
        {{ pageNum }}
      </ng-template>
    </ngb-pagination>
  </div>
</div>

<app-rule-issues-supplementary-table
  *ngIf="recommendationsTable.dataLength > 0"
  [tableController]="recommendationsTable"
  [title]="'recommendations'"
  [description]="'best_practices_table_description'"
  [name]="'recommendations'"
  [topPaginationAriaLabel]="'aria_label_scan_results_recommendations_table_top_pagination'"
  [bottomPaginationAriaLabel]="'aria_label_scan_results_recommendations_table_bottom_pagination'"
>
</app-rule-issues-supplementary-table>

<app-rule-issues-supplementary-table
  *ngIf="showRuleIssuesSupplementartyTable"
  [tableController]="manualReviewTable"
  [title]="'manual_review_required'"
  [description]="'manual_review_required_description'"
  [name]="'manual_review'"
  [topPaginationAriaLabel]="'aria_label_scan_results_manual_review_table_top_pagination'"
  [bottomPaginationAriaLabel]="'aria_label_scan_results_manual_review_table_bottom_pagination'"
>
</app-rule-issues-supplementary-table>
