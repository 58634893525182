import { Directive, HostBinding, Input } from '@angular/core';
import { TranslateService } from '../../translate/translate.service';

@Directive({
  selector: '[appAriaLabelSort]',
})
export class AriaLabelSortDirective {
  @Input()
  appAriaLabelSort: string;
  @Input()
  sortColumn: string;

  @HostBinding('attr.aria-label') get ariaLabel(): string {
    const sortCol = this.translateService.instant(this.sortColumn);
    const sortDir = this.translateService.instant(this.appAriaLabelSort ?? 'none').toLowerCase();
    const sortTxt = this.translateService.instant('label_sort').toLowerCase();
    return `${sortCol} ${sortDir} ${sortTxt}`;
  }

  constructor(private translateService: TranslateService) {}
}
