import { Component, ElementRef, HostBinding, NgZone, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NotificationService } from '../../services/notification.service';
import { NotificationPosition, NotificationType } from '../../models/notification.model';
import { INotification } from '../../interfaces/notification.interface';
import { SharedTextUtility } from '../../../../shared/utils/text.utility';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { CommonUtility } from '../../utility/common.utility';

const TOAST_FOCUS_DELAY: number = 100;

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;
  @HostBinding('class.toast-container') toastContainer: string = 'true';

  public timeout: number;

  public toasts: INotification[];

  constructor(
    private element: ElementRef<HTMLElement>,
    private notificationService: NotificationService,
    private router: Router,
    private zone: NgZone,
  ) {
    this.subscriptions = new Subscription();
    this.timeout = 15000; // mseconds
    this.toasts = [];
  }

  private processNotificationOnRouteChanges = (event: Event): void => {
    if (event instanceof NavigationStart) {
      this.clearOnRouteChange();
    }
  };

  private processToast(toast: INotification): void {
    if (SharedCommonUtility.getTypeOf(toast) === 'object' && toast.position !== NotificationPosition.Toast) {
      return;
    }

    if (toast === null) {
      this.clearAll(toast);
      return;
    }

    if (typeof toast.message === 'string') {
      toast.message = SharedTextUtility.removeHTML(toast.message);
    }

    if (toast.shouldTrapFocus) {
      toast.autoHide = false;
    }

    this.toasts.push(toast);

    this.handleFocus();
  }

  private handleFocus(): void {
    this.clearFocusTrap();
    if (this.toasts.some((t: INotification) => t.shouldTrapFocus)) {
      this.setFocusTrap();
    } else {
      this.focusToast();
    }
  }

  private focusToast(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        (this.element.nativeElement.querySelector('a, button') as HTMLButtonElement)?.focus();
      }, TOAST_FOCUS_DELAY);
    });
  }

  private setFocusTrap(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const toastElement: HTMLElement = this.element.nativeElement.querySelector('[data-focus-trap=true]');
        const focusableElement: HTMLButtonElement = toastElement?.querySelector<HTMLButtonElement>('a, button');
        focusableElement?.focus();
        CommonUtility.activateFocusTrap(toastElement);
      });
    });
  }

  private clearFocusTrap(): void {
    CommonUtility.deactivateFocusTrap();
  }

  private clearAll(notification?: INotification): void {
    if (notification === null) {
      this.toasts = [];
    } else {
      this.remove(notification);
    }
  }

  private clearOnRouteChange(): void {
    const keepOnRouteChange = (toast: INotification): boolean => {
      return toast.keepAfterRouteChange;
    };

    this.toasts = this.toasts.filter(keepOnRouteChange);
    this.clearFocusTrap();
  }

  public remove(toastToRemove: INotification): void {
    const removeSelectedToast = (toast: INotification): boolean => {
      return toast !== toastToRemove;
    };

    this.toasts = this.toasts.filter(removeSelectedToast);
    this.handleFocus();
  }

  public cssClass(toast: INotification): string {
    const defaultClassName: string = '';

    if (SharedCommonUtility.getTypeOf(toast) !== 'object') {
      return defaultClassName;
    }

    switch (toast.type) {
      case NotificationType.Success:
        return 'alert-success';
      case NotificationType.Error:
        return 'alert-danger';
      case NotificationType.Info:
        return 'bg-white';
      case NotificationType.Warning:
        return 'alert-warning';
      default:
        return defaultClassName;
    }
  }

  public isTemplate(toast: INotification): boolean {
    return toast.message instanceof TemplateRef;
  }

  public ngOnInit(): void {
    const processToastSubscription: Subscription = this.notificationService
      .getNotification()
      .subscribe(this.processToast.bind(this));
    const processNotificationOnRouteChangesSubscription: Subscription = this.router.events.subscribe(
      this.processNotificationOnRouteChanges.bind(this),
    );

    this.subscriptions.add(processToastSubscription).add(processNotificationOnRouteChangesSubscription);
  }

  public ngOnDestroy(): void {
    this.clearAll(null);
    this.subscriptions.unsubscribe();
    this.clearFocusTrap();
  }
}
