<div>
  <h4 class="mb-1">
    {{ findingSeverity | translate }}
  </h4>
  <div class="d-flex align-items-center">
    <div class="aging-issues-number h1 m-0" >{{ agingIssues | compactNumber }}</div>
  </div>
  <div>
    <span [innerHTML]="'aged_unresolved_findings_age' | translateHtml: [severityTimeInterval]"></span>
    <ds-icon
      *ngIf="agingIssues > 0"
      class="m-2 overdue-findings-icon"
      
      tabindex="0"
      [icon]="Icons.Flag"
      [iconStyle]="IconStyles.Solid"
      [tooltipPlacement]="DsTooltipPosition.top"
      [ds-tooltip]="'policy_violated' | translate"
      [attr.aria-label]="'policy_violated' | translate"
      [style.color]="EapColors.RedError"
    ></ds-icon>
  </div>
  <div class="mb-3" *ngIf="scanId && agingIssues">
    <a
      
      [routerLink]="['/', Api.manual_evaluations, scanId, Api.results]"
      [queryParams]="{
        status: AuditFindingQAStatusUnresolved.join(','),
        severity: severity[findingSeverity],
        age: severityTimeInterval,
      }"
    >
      {{ 'view_findings' | translate }}
    </a>
  </div>
</div>
